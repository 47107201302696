<template>
  <div class="wlnh">
    <div class="left-box">
      <ul>
        <li class="items1">
          <div class="items1_title">进水总量</div>
          <div
            class="items1_value"
            @click="toCompon(2, 'AN213_JLT', 'DB1V', 'AN213', '进水总量')"
            @dblclick="
              Cclick(infoList.DB1V.AN213, 'AN213_JLT', 'DB1V', 'AN213')
            "
          >
            {{ infoList.DB1V.AN213 }} m³
          </div>
        </li>
        <li>
          <div class="line-arrow">
            <div class="line"></div>
          </div>
        </li>
        <li class="items1 jiange">
          <div class="items1_title">蒸汽总量</div>
          <div
            class="items1_value"
            @click="toCompon(2, 'AN141_JLT', 'DB1V', 'AN141', '蒸汽总量')"
            @dblclick="
              Cclick(infoList.DB1V.AN141, 'AN141_JLT', 'DB1V', 'AN141')
            "
          >
            {{ infoList.DB1V.AN141 }} L
          </div>
        </li>
        <li>
          <div class="line-arrow">
            <div class="line"></div>
          </div>
        </li>
      </ul>
    </div>
    <div class="center-box">
      <div class="center-box-top">
        <div class="box1">
          <div class="line1">换热装置</div>
          <div class="line2">
            <div class="line2-item"></div>
            <div class="line2-item lable_color">累积值</div>
            <div class="line2-item lable_color">实时值</div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">用电量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN207_JLT', 'DB1V', 'AN207', '用电量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN207, 'AN207_JLT', 'DB1V', 'AN207')">
              {{ infoList.DB1V.AN207 }}Kwh
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN215_JLT', 'DB1V', 'AN215', '用电量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN215, 'AN215_JLT', 'DB1V', 'AN215')">
              {{ infoList.DB1V.AN215 }}Kwh
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
        </div>
        <div class="box1">
          <div class="line1">公用工程</div>
          <div class="line2">
            <div class="line2-item"></div>
            <div class="line2-item lable_color">累积值</div>
            <div class="line2-item lable_color">实时值</div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">用电量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN208_JLT', 'DB1V', 'AN208', '用电量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN208, 'AN208_JLT', 'DB1V', 'AN208')">
              {{ infoList.DB1V.AN208 }}Kwh
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN216_JLT', 'DB1V', 'AN216', '用电量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN216, 'AN216_JLT', 'DB1V', 'AN216')">
              {{ infoList.DB1V.AN216 }}Kwh
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
        </div>
        <div class="box1">
          <div class="line1">精馏装置</div>
          <div class="line2">
            <div class="line2-item"></div>
            <div class="line2-item lable_color">累积值</div>
            <div class="line2-item lable_color">实时值</div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">用电量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN209_JLT', 'DB1V', 'AN209', '用电量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN209, 'AN209_JLT', 'DB1V', 'AN209')">
              {{ infoList.DB1V.AN209 }}Kwh
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN217_JLT', 'DB1V', 'AN217', '用电量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN217, 'AN217_JLT', 'DB1V', 'AN217')">
              {{ infoList.DB1V.AN217 }}Kwh
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">进料量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN132_JLT', 'DB1V', 'AN132', '进料量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN132, 'AN132_JLT', 'DB1V', 'AN132')">
              {{ infoList.DB1V.AN132 }}L</div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'FI101', 'DB1S', 'FI101', '进料量实时值')"
              @dblclick="
                Cclick(infoList.DB1S.FI101, 'FI101', 'DB1S', 'FI101')">
              {{ infoList.DB1S.FI101 }}L/h
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">回流量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN133_JLT', 'DB1V', 'AN133', '回流量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN133, 'AN133_JLT', 'DB1V', 'AN133')">
              {{ infoList.DB1V.AN133 }}L
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN180_JLT', 'DB1V', 'AN180', '回流量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN180, 'AN180_JLT', 'DB1V', 'AN180')">
              {{ infoList.DB1V.AN180 }}L/h
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">采出量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN134_JLT', 'DB1V', 'AN134', '采出量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN134, 'AN134_JLT', 'DB1V', 'AN134')">
              {{ infoList.DB1V.AN134 }}L
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN181_JLT', 'DB1V', 'AN181', '采出量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN181, 'AN181_JLT', 'DB1V', 'AN181')">
              {{ infoList.DB1V.AN181 }}L/h
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">残液量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN135_JLT', 'DB1V', 'AN135', '残液量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN135, 'AN135_JLT', 'DB1V', 'AN135')">
              {{ infoList.DB1V.AN135 }}L
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'FI104', 'DB1S', 'FI104', '残液量实时值')"
              @dblclick="
                Cclick(infoList.DB1S.FI104, 'FI104', 'DB1S', 'FI104')">
              {{ infoList.DB1S.FI104 }}L/h
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">冷凝水</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN136_JLT', 'DB1V', 'AN136', '冷凝水累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN136, 'AN136_JLT', 'DB1V', 'AN136')">
              {{ infoList.DB1V.AN136 }}L
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'FI104', 'DB1S', 'FI104', '冷凝水实时值')"
              @dblclick="
                Cclick(infoList.DB1S.FI104, 'FI104', 'DB1S', 'FI104')">
              {{ infoList.DB1S.FI104 }}L/h
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
        </div>
      </div>
      <div class="center-box-top">
        <div class="box1">
          <div class="line1">反应釜装置</div>
          <div class="line2">
            <div class="line2-item"></div>
            <div class="line2-item lable_color">累积值</div>
            <div class="line2-item lable_color">实时值</div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">用电量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN210_JLT', 'DB1V', 'AN210', '反应釜装置用电量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN210, 'AN210_JLT', 'DB1V', 'AN210')">
              {{ infoList.DB1V.AN210 }}Kwh
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN218_JLT', 'DB1V', 'AN218', '反应釜装置用电量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN218, 'AN218_JLT', 'DB1V', 'AN218')">
              {{ infoList.DB1V.AN218 }}Kw
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
        </div>
        <div class="box1">
          <div class="line1">四容水槽装置</div>
          <div class="line2">
            <div class="line2-item"></div>
            <div class="line2-item lable_color">累积值</div>
            <div class="line2-item lable_color">实时值</div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">用电量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN211_JLT', 'DB1V', 'AN211', '四容水槽装置用电量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN211, 'AN211_JLT', 'DB1V', 'AN211')">
              {{ infoList.DB1V.AN211 }}Kwh
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN219_JLT', 'DB1V', 'AN219', '四容水槽装置用电量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN219, 'AN219_JLT', 'DB1V', 'AN219')">
              {{ infoList.DB1V.AN219 }}Kw
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
        </div>
        <div class="box1">
          <div class="line1">干燥装置</div>
          <div class="line2">
            <div class="line2-item"></div>
            <div class="line2-item lable_color">累积值</div>
            <div class="line2-item lable_color">实时值</div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color">用电量</div>
            <div
              class="line2-item value_color"
              @click="toCompon(2, 'AN212_JLT', 'DB1V', 'AN212', '干燥装置用电量累积值')"
              @dblclick="
                Cclick(infoList.DB1V.AN212, 'AN212_JLT', 'DB1V', 'AN212')">
              {{ infoList.DB1V.AN212 }}Kwh
            </div>
            <div class="line2-item value_color"
              @click="toCompon(2, 'AN220_JLT', 'DB1V', 'AN220', '干燥装置用电量实时值')"
              @dblclick="
                Cclick(infoList.DB1V.AN220, 'AN220_JLT', 'DB1V', 'AN220')">
              {{ infoList.DB1V.AN220 }}Kw
            </div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
          <div class="line2">
            <div class="line2-item lable_color"></div>
            <div class="line2-item value_color"></div>
            <div class="line2-item value_color"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <ul>
        <li class="items1">
          <div class="items1_title">废气总量</div>
          <div class="items1_value"
          @click="toCompon(2, 'FI203', 'DB1S', 'FI203', '废气总量')"
              @dblclick="
                Cclick(infoList.DB1S.FI203, 'FI203', 'DB1S', 'FI203')">
              {{ infoList.DB1S.FI203 }}Nm3/h
         </div>
        </li>
        <li>
          <div class="line-arrow">
            <div class="line"></div>
          </div>
        </li>
        <li class="items1 jiange">
          <div class="items1_title">回水总量</div>
          <div class="items1_value"
          @click="toCompon(2, 'AN214_JLT', 'DB1V', 'AN214', '回水总量')"
              @dblclick="
                Cclick(infoList.DB1V.AN214, 'AN214_JLT', 'DB1V', 'AN214')">
              {{ infoList.DB1V.AN214 }}m³
         </div>
        </li>
        <li>
          <div class="line-arrow">
            <div class="line"></div>
          </div>
        </li>
      </ul>
    </div>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
  </div>
</template>

<script>
import index from "./_index/index.js";
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  components: {
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  mixins: [index],
};
</script>
<style scoped lang="scss">
.wlnh {
  display: flex;
  padding-top: 2vh;
  box-sizing: border-box;
  .left-box {
    width: 15vw;
    height: 80vh;
    display: flex;
    align-items: center;
    margin-left: 5vw;
    margin-right: 1vw;
  }
  .right-box {
    width: 15vw;
    height: 80vh;
    display: flex;
    align-items: center;
    // margin-left: 5vw;
    margin-right: 1vw;
  }
  .center-box {
    width: 60vw;
    height: 80vh;
    border: 1px solid #67989d;
    border-bottom: none;

    .center-box-top {
      display: flex;
    }
    .box1 {
      width: 20vw;
      // border-right: 1px solid #67989d;
      // border-bottom: 1px solid #67989d;
      height: 40vh;
      .line1 {
        width: 19.95vw;
        height: 5vh;
        color: #ff09db;
        border-bottom: 1px solid #67989d;
        border-right: 1px solid #67989d;
        line-height: 5vh;
        text-align: center;
      }
      .line2 {
        display: flex;
        color: #01fff6;
        .line2-item {
          width: 6.7vw;
          height: 4.3vh;
          line-height: 4.3vh;
          border-bottom: 1px solid #67989d;
          border-right: 1px solid #67989d;
          text-align: center;
        }
      }
      .value_color {
        color: #d9feff;
      }
      .lable_color {
        color: #458ca4;
      }
    }
  }
  .items1 {
    display: flex;
    width: 15vw;
    height: 4vh;
    line-height: 4vh;
    color: #01fff6;
    // border: 1px solid #fff;
    text-align: center;
  }
  .jiange {
    margin-top: 4vh;
  }
  .items1_title {
    color: #ff09db;
    width: 5vw;
    height: 4vh;
  }
  .items1_value {
    width: 5vw;
    height: 3.5vh;
    color: #01fff6;
  }
  .line-arrow {
    display: flex;
    align-items: center;
  }
  .line {
    flex-grow: 1;
    height: 1px;
    background-color: rgb(248, 242, 242);
    position: relative;
  }
  .line::after {
    content: "";
    position: absolute;
    right: -10px; /* Adjust as needed */
    top: 20%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid rgb(248, 245, 245);
  }
}
</style>
